
import './App.css';
import React, { Component } from 'react'; //different
import "@fontsource/kanit"; // Defaults to weight 400
import "@fontsource/kanit/400.css"; // Specify weight
import "@fontsource/kanit/400-italic.css"; // Specify weight and style
class App extends Component {

    async componentDidMount() {
    }
    render() {
        return (
            <div>
                <section id="topbar" className="topbar d-flex align-items-center">
                    <div className="container d-flex justify-content-center justify-content-md-between">
                        <div className="contact-info d-flex align-items-center">
                            <img src="telegram.svg" className="bi bi-phone d-flex align-items-center ms-4" width={18} height={18} alt="" data-aos="zoom-out" data-aos-delay={100} /> <a style={{ marginLeft: "5px", color: "white" }} href='https://t.me/FiversTH'>@FiversTH</a>
                            {/* <i className="bi bi-phone d-flex align-items-center ms-4"></i> */}
                        </div>
                        <div className="social-links d-none d-md-flex align-items-center">
                            <a href="#" className="twitter"><i className="bi bi-twitter" /></a>
                            <a href="#" className="facebook"><i className="bi bi-facebook" /></a>
                            <a href="#" className="instagram"><i className="bi bi-instagram" /></a>
                            <a href="#" className="linkedin"><i className="bi bi-linkedin" /></a>
                        </div>
                    </div>
                </section>
                <header id="header" className="header d-flex align-items-center">
                    <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
                        <a href="index.html" className="logo d-flex align-items-center">
                            {/* Uncomment the line below if you also wish to use an image logo */}
                            {/* <img src="assets/img/logo.png" alt=""> */}
                            <h1>Fiverbet X<span>.</span></h1>
                        </a>
                        <nav id="navbar" className="navbar">
                            <ul>
                                <li><a href="#hero">หน้าแรก</a></li>
                                <li><a href="#about">ติดต่อเรา</a></li>
                                <li><a href="#portfolio">ผลงาน</a></li>
                                <li><a href="#pricing">ตรวจสอบราคา</a></li>
                                {/* <li><a href="blog.html">บทความ</a></li> */}
                                <li className="dropdown"><a href="#"><span>บริการของเรา</span> <i className="bi bi-chevron-down dropdown-indicator" /></a>
                                    <ul>
                                        <li><a href="#">ปรึกษาเรื่อง SEO</a></li>
                                        <li className="dropdown"><a href="#"><span>กระดาน</span> <i className="bi bi-chevron-down dropdown-indicator" /></a>
                                            <ul>
                                                <li><a href="#">WhiteLable</a></li>
                                                <li><a href="#">หวย 100%</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="#">ระบบออโต้</a></li>
                                        <li><a href="#">เชื่อมต่อ API</a></li>
                                        <li><a href="#">ปรึกษาการตลาด</a></li>
                                    </ul>
                                </li>
                                <li><a href="#faq">คำถาม</a></li>
                            </ul>
                        </nav>{/* .navbar */}
                        <i className="mobile-nav-toggle mobile-nav-show bi bi-list" />
                        <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x" />
                    </div>
                </header>
            </div>

        );
    }
}

export default App;
